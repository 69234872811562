<template>
  <div class="customer-box w-box mb-20">
    <h2>
      <aRow type="flex" justify="space-between">
        <aCol>
          <img
            class="mr-5"
            src="@/assets/images/dashboard/contracts/customer.png"
            alt="customer"
          />
          Contratante
        </aCol>

        <aCol>
          <a-tooltip v-if="validateAllContractFields.customerStatus.alert">
            <template slot="title">
              Verifique se os dados do contratante estão completos
            </template>
            <img
              class="c-pointer mr-10"
              src="@/assets/images/dashboard/contracts/danger.png"
              alt="img"
              width="15"
            />
          </a-tooltip>
          <a-icon
            class="c-pointer grayscale relative"
            type="edit-svg"
            style="zoom: 0.026; top: 95px"
            @click="onClickOpenCustomerModal"
          />
        </aCol>
      </aRow>
    </h2>

    <aSkeleton v-if="!tempContract.the_customer" />

    <div
      v-if="tempContract.the_customer"
      class="row bold upper c-pointer"
      @click="$router.push(`/customers/edit/${tempContract.the_customer.id}`)"
    >
      <span class="img">
        <img
          src="@/assets/images/dashboard/contracts/customer-name.png"
          alt="img"
        />
      </span>
      {{
        tempContract.the_customer.id ? tempContract.the_customer.id + " - " : ""
      }}

      <span v-if="tempContract.the_customer.person_type == 'Pessoa Jurídica'">
        {{ tempContract.the_customer.trading_name }}
      </span>
      <span v-else>
        {{ tempContract.the_customer.first_name }}
        {{ tempContract.the_customer.last_name }}
      </span>
    </div>

    <div class="row bold" v-if="tempContract.the_customer">
      <span class="img">
        <img
          src="@/assets/images/dashboard/contracts/customer-phone.png"
          alt="img"
        />
      </span>
      {{ tempContract.the_customer.mobile_phone }}
      {{
        tempContract.the_customer.phone
          ? ` ou ${tempContract.the_customer.phone}`
          : ""
      }}
    </div>

    <div class="row bold" v-if="tempContract.the_customer">
      <span class="img">
        <img
          src="@/assets/images/dashboard/contracts/customer-email.png"
          alt="img"
        />
      </span>
      {{ tempContract.the_customer.email }}
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ContractCustomerSection",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    validateAllContractFields: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      openCustomerModal: false,
    };
  },
  mounted() {},
  methods: {
    onClickOpenCustomerModal() {
      this.$emit("onClickOpenCustomerModal");
    },
  },
};
</script>

<style lang="sass" scoped>
.w-box
  padding: 15px 15px !important
.customer-box
  .row
    padding-left: 0
    padding-right: 0
  .row
    padding: 5px 0px
    font-size: 13px
    font-weight: 600
    width: 314px
    transition: .3s
    max-width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    .img
      -webkit-filter: grayscale(1)
      -webkit-filter: grayscale(100%)
      width: 24px
      display: inline-block
      text-align: center
  h2
      font-size: 14px
      font-weight: 400
      border-bottom: 1px solid #ddd
      padding: 0 0 10px 0 !important
      color: #59595b !important
</style>
